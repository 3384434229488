/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const handleIE = () => {
  if (typeof window !== "undefined") {
    let ua = window.navigator.userAgent
    const isIE = () => ua.match(/(MSIE|Trident)/)
    
    if(isIE()) {
      alert('Delight works best with modern browsers. Please use Chrome or Firefox')
      window.location.href = "https://www.google.com/chrome/";
    }
  }
}

exports.onClientEntry = () => {
  handleIE()
}