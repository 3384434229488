// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-en-js": () => import("./../../../src/pages/api.en.js" /* webpackChunkName: "component---src-pages-api-en-js" */),
  "component---src-pages-api-zh-hk-js": () => import("./../../../src/pages/api.zh-hk.js" /* webpackChunkName: "component---src-pages-api-zh-hk-js" */),
  "component---src-pages-api-zh-js": () => import("./../../../src/pages/api.zh.js" /* webpackChunkName: "component---src-pages-api-zh-js" */),
  "component---src-pages-commands-alexa-js": () => import("./../../../src/pages/commands/alexa.js" /* webpackChunkName: "component---src-pages-commands-alexa-js" */),
  "component---src-pages-commands-google-js": () => import("./../../../src/pages/commands/google.js" /* webpackChunkName: "component---src-pages-commands-google-js" */),
  "component---src-pages-commands-index-js": () => import("./../../../src/pages/commands/index.js" /* webpackChunkName: "component---src-pages-commands-index-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-zh-hk-js": () => import("./../../../src/pages/contact.zh-hk.js" /* webpackChunkName: "component---src-pages-contact-zh-hk-js" */),
  "component---src-pages-contact-zh-js": () => import("./../../../src/pages/contact.zh.js" /* webpackChunkName: "component---src-pages-contact-zh-js" */),
  "component---src-pages-creator-en-js": () => import("./../../../src/pages/creator.en.js" /* webpackChunkName: "component---src-pages-creator-en-js" */),
  "component---src-pages-creator-zh-hk-js": () => import("./../../../src/pages/creator.zh-hk.js" /* webpackChunkName: "component---src-pages-creator-zh-hk-js" */),
  "component---src-pages-creator-zh-js": () => import("./../../../src/pages/creator.zh.js" /* webpackChunkName: "component---src-pages-creator-zh-js" */),
  "component---src-pages-customers-en-js": () => import("./../../../src/pages/customers.en.js" /* webpackChunkName: "component---src-pages-customers-en-js" */),
  "component---src-pages-customers-zh-hk-js": () => import("./../../../src/pages/customers.zh-hk.js" /* webpackChunkName: "component---src-pages-customers-zh-hk-js" */),
  "component---src-pages-customers-zh-js": () => import("./../../../src/pages/customers.zh.js" /* webpackChunkName: "component---src-pages-customers-zh-js" */),
  "component---src-pages-ihome-js": () => import("./../../../src/pages/ihome.js" /* webpackChunkName: "component---src-pages-ihome-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-zh-hk-js": () => import("./../../../src/pages/index.zh-hk.js" /* webpackChunkName: "component---src-pages-index-zh-hk-js" */),
  "component---src-pages-index-zh-js": () => import("./../../../src/pages/index.zh.js" /* webpackChunkName: "component---src-pages-index-zh-js" */),
  "component---src-pages-pricing-ecommerce-en-js": () => import("./../../../src/pages/pricing-ecommerce.en.js" /* webpackChunkName: "component---src-pages-pricing-ecommerce-en-js" */),
  "component---src-pages-pricing-ecommerce-zh-hk-js": () => import("./../../../src/pages/pricing-ecommerce.zh-hk.js" /* webpackChunkName: "component---src-pages-pricing-ecommerce-zh-hk-js" */),
  "component---src-pages-pricing-ecommerce-zh-js": () => import("./../../../src/pages/pricing-ecommerce.zh.js" /* webpackChunkName: "component---src-pages-pricing-ecommerce-zh-js" */),
  "component---src-pages-pricing-en-js": () => import("./../../../src/pages/pricing.en.js" /* webpackChunkName: "component---src-pages-pricing-en-js" */),
  "component---src-pages-pricing-zh-hk-js": () => import("./../../../src/pages/pricing.zh-hk.js" /* webpackChunkName: "component---src-pages-pricing-zh-hk-js" */),
  "component---src-pages-pricing-zh-js": () => import("./../../../src/pages/pricing.zh.js" /* webpackChunkName: "component---src-pages-pricing-zh-js" */),
  "component---src-pages-terms-en-js": () => import("./../../../src/pages/terms.en.js" /* webpackChunkName: "component---src-pages-terms-en-js" */),
  "component---src-pages-terms-zh-hk-js": () => import("./../../../src/pages/terms.zh-hk.js" /* webpackChunkName: "component---src-pages-terms-zh-hk-js" */),
  "component---src-pages-terms-zh-js": () => import("./../../../src/pages/terms.zh.js" /* webpackChunkName: "component---src-pages-terms-zh-js" */)
}

